<template>
  <v-container fluid>
    <v-layout>
      <v-flex>
        <page-title text="Fornitori" />
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex>
        <form class="mt-4" @submit.prevent="changePage(1)">
          <v-text-field
            light
            v-model="search"
            name="cerca"
            prepend-icon="search"
            @click:prepend="getFornitori"
            append-icon="cancel"
            @click:append="resetItems"
            placeholder="Cerca" />
        </form>
        <v-alert
          outline
          color="info"
          value="true"
          v-if="!fornitori.length">
          Nessun fornitore trovato
        </v-alert>
        <v-list class="mt-2" two-line v-if="fornitori.length">
          <template v-for="(fornitore, index) in fornitori">
            <v-list-tile @click="openDialog(fornitore)" :key="'tile-'+fornitore._id">
              <v-list-tile-content>
                <v-list-tile-title class="text-capitalize" v-text="fornitore.nome" />
                <v-list-tile-sub-title v-text="fornitore.targa" />
              </v-list-tile-content>
              <v-list-tile-action>
                <v-chip small :color="!fornitore.attivo ? 'grey dark-3' : 'success'" text-color="white">
                  <span v-if="fornitore.attivo">Attivo</span>
                  <span v-if="!fornitore.attivo">Non attivo</span>
                </v-chip>
                <v-icon @click.stop="deleteFornitore(fornitore)" color="error">delete</v-icon>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider :key="fornitore._id" v-if="index < fornitori.length - 1" />
          </template>
        </v-list>
        <v-pagination
          class="mt-4"
          v-if="pages > 1"
          :length="pages"
          :value="page"
          @input="changePage($event)" />
      </v-flex>
      <v-dialog
        v-model="showDialog"
        transition="dialog-bottom-transition"
        :overlay="false"
        width="500">
        <v-card>
          <v-toolbar class="grey lighten-3" card>
            <v-toolbar-title>
              <span v-if="fornitore._id">{{fornitore.nome}}</span>
              <span v-if="!fornitore._id">Nuovo fornitore</span>
            </v-toolbar-title>
            <v-spacer />
            <v-btn icon @click.stop="showDialog=false"><v-icon>close</v-icon></v-btn>
          </v-toolbar>
          <v-card-text class="pa-0">
            <v-card>
              <v-form ref="formFornitore" v-model="validFormFornitore">
                <v-container fluid grid-list-xl>
                  <v-layout row wrap>
                    <v-flex>
                      <v-text-field
                        label="Nome*"
                        v-model="fornitore.nome"
                        name="nome"
                        :rules="[$rules.required, $rules.maxlength(100)]" />
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex>
                      <v-select
                        label="Stato"
                        :items="stati"
                        item-text="nome"
                        item-value="id"
                        v-model="fornitore.attivo"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex>
                      <v-autocomplete
                        v-model="fornitore.clienti"
                        :disabled="isUpdating"
                        :items="clienti"
                        box
                        chips
                        color="blue-grey lighten-2"
                        label="Associa un Cliente"
                        item-text="ragione_sociale"
                        item-value="_id"
                        multiple
                        class="autocompleteClienti">
                        <template v-slot:selection="clienti">
                          <v-chip
                            :selected="clienti.selected"
                            close
                            class="chip--select-multi"
                            @input="removeCliente(clienti.item._id)">
                            {{ clienti.item.ragione_sociale }}
                          </v-chip>
                        </template>
                        <template v-slot:item="clienti">
                          <template>
                            <v-list-tile-content>
                              <v-list-tile-title v-html="clienti.item.ragione_sociale"></v-list-tile-title>
                            </v-list-tile-content>
                          </template>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex>
                      <v-btn
                        class="ml-0"
                        color="primary"
                        :disabled="!validFormFornitore"
                        id="salvaFornitore"
                        @click.native="save()">
                        Salva
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-tooltip left>
        <v-btn slot="activator" color="pink darken-1" dark fab fixed right bottom @click.stop="openDialog()">
          <v-icon>add</v-icon>
        </v-btn>
        <span>Nuovo fornitore</span>
      </v-tooltip>
    </v-layout>
    <modal-confirm ref="confirm" />
  </v-container>
</template>

<script>
import { $EventBus } from '@/eventBus'
import PageTitle from '@/components/common/PageTitle'
import modalConfirm from '@/components/common/ModalConfirm'
import _clone from 'lodash/clone'

export default {
  data () {
    return {
      clienti: [],
      fornitori: [],
      fornitore: {},
      search: null,
      isUpdating: false,
      pages: 0,
      page: 1,
      paginate: 1000,
      validFormFornitore: false,
      stati: [{ id: 1, nome: 'Attivo' }, { id: 0, nome: 'Non attivo' }],
      showDialog: false
    }
  },
  components: {
    PageTitle,
    'modal-confirm': modalConfirm
  },
  methods: {
    getFornitori () {
      this.$plsqlReadAll('fornitore', {
        what: this.search,
        page: this.page,
        rows4page: this.paginate
      })
        .then(response => {
          if (!response.data.items.length && this.page > 1) {
            this.changePage(this.page - 1)
          } else {
            this.fornitori = response.data.items
            this.pages = response.data.pages
          }
        })
        .catch(err => {
          console.log('errore lettura fornitori', err)
          this.$showMessage({ type: 'error', text: 'Errore' })
        })
    },
    getClienti () {
      this.$plsqlReadAll('cliente', {
        page: 1,
        rows4page: 1000
      })
        .then(response => {
          this.clienti = response.data.items
        })
        .catch(err => {
          console.log('errore lettura clienti', err)
          this.$showMessage({ type: 'error', text: 'Errore' })
        })
    },
    deleteFornitore (fornitore) {
      this.fornitore = fornitore
      this.$refs.confirm.show({
        modalTitle: 'Sicuro?',
        modalText: 'Stai per cancellare il fornitore <strong>' + fornitore.nome + '</strong>.<br/>Confermi?',
        modalButtonOk: 'Elimina',
        modalFunctionOk: this.remove,
        modalObj: fornitore
      })
    },
    remove () {
      this.$plsqlDelete('fornitore', this.fornitore._id)
        .then(response => {
          $EventBus.$emit('message', {
            type: 'success',
            text: 'Fornitore eliminato'
          })
          this.$refs.confirm.hide()
          this.getFornitori()
        })
        .catch(err => {
          if (err.data.errCode === 'ORA-02292') {
            $EventBus.$emit('message', { type: 'error', text: 'Non è possibile cancellare il fornitore perché usato' })
          } else {
            $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
          }
          this.$refs.confirm.hide()
        })
    },
    removeCliente (id) {
      const index = this.fornitore.clienti.indexOf(id)
      if (index >= 0) this.fornitore.clienti.splice(index, 1)
    },
    resetItems () {
      if (this.search) {
        this.search = null
        this.changePage(1)
      }
    },
    changePage (page) {
      this.page = page
      this.getFornitori()
    },
    save () {
      if (this.$refs.formFornitore.validate()) {
        this.$plsqlSave('fornitore', this.fornitore)
          .then(response => {
            $EventBus.$emit('message', {
              type: 'success',
              text: 'Fornitore salvato'
            })
            this.showDialog = false
            this.getFornitori()
          })
          .catch(err => {
            console.log(err)
            $EventBus.$emit({ type: 'error', text: 'Errore salvataggio fornitore' })
          })
      }
    },
    openDialog (fornitore) {
      this.fornitore = fornitore ? _clone(fornitore) : { }
      this.showDialog = true
      if (!fornitore) this.$refs.formFornitore.reset()
    }
  },
  mounted () {
    this.getFornitori()
    this.getClienti()
  }
}
</script>

<style>
  .autocompleteClienti .v-icon {
    cursor: pointer!important;
  }
</style>
